import request from '@/utils/request'

export function ServiceChat_index(data) {
    return request({
        url: '/merchant/ServiceChat/index',
        method: 'get',
        params: data
    })
}


export function ServiceChat_getChatInfo(data) {
    return request({
        url: '/merchant/ServiceChat/getChatInfo',
        method: 'get',
        params: data
    })
}


export function ServiceChat_add(data) {
    return request({
        url: '/merchant/ServiceChat/add',
        method: 'post',
        data: data
    })
}

export function ServiceChat_getUnread(data) {
    return request({
        url: '/merchant/ServiceChat/getUnread',
        method: 'get',
        params: data
    })
}

export function ServiceChat_recall(data) {
    return request({
        url: '/merchant/ServiceChat/recall',
        method: 'post',
        data: data
    })
}
