/**
 * 
 * @param {*} message 
 * @returns 判断客服信息是否可以撤回
 */
export function canRecall(message) {
    if (!message || !message.create_at || message.is_service !== 1) {
        return false;
    }

    // 将消息的时间转为Date对象
    const msgTime = new Date(message.create_at);
    // 获取当前时间
    const now = new Date();
    // 计算时间差（毫秒）
    const timeDiff = now - msgTime;
    // 判断是否在2分钟内（120000毫秒）
    return timeDiff <= 120000;
}

// 判断客服信息是否应该显示时间戳
export function shouldShowTimestamp(index) {
    if (index === 0) return true; // 第一条消息总是显示时间

    const currentMsg = this.chatList[index];
    const prevMsg = this.chatList[index - 1];

    if (!currentMsg.create_at || !prevMsg.create_at) return false;

    // 计算时间差（分钟）
    const currentTime = new Date(currentMsg.create_at).getTime();
    const prevTime = new Date(prevMsg.create_at).getTime();
    const diffMinutes = (currentTime - prevTime) / (1000 * 60);

    // 如果时间差大于10分钟，则显示时间戳
    return diffMinutes >= 10;
}

// 格式化消息时间显示
export function formatMessageTime(timeStr) {
    if (!timeStr) return "";

    const date = new Date(timeStr);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const isToday = date >= today;
    const isYesterday = date >= yesterday && date < today;

    const pad = (num) => String(num).padStart(2, "0");
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    if (isToday) {
        return `今天 ${hours}:${minutes}`;
    } else if (isYesterday) {
        return `昨天 ${hours}:${minutes}`;
    } else {
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
            date.getDate()
        )} ${hours}:${minutes}`;
    }
}