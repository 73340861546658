<template>
  <div class="mask-popup" v-show="visible">
    <!-- 遮罩层 -->
    <div class="mask" @click="handleMaskClick"></div>
    <!-- 内容区域 -->
    <div class="popup-content chat-container">
      <!-- 左侧聊天列表 -->
      <div class="chat-list">
        <!-- <div class="search-box">
          <i class="search-icon">🔍</i>
          <input type="text" placeholder="搜索" v-model="searchText" />
        </div> -->
        <div
          class="chat-list-content"
          @scroll="handleScroll"
          ref="chatListContent"
        >
          <div
            class="chat-item"
            v-for="(item, index) in userChatList"
            :key="index"
            :class="{ active: currentChat.id === item.id }"
            @click="selectChat(item)"
          >
            <div
              class="avatar"
              style="
                 {
                  backgroundcolor: #4169e1;
                }
              "
            >
              默
            </div>
            <div class="chat-info">
              <div class="chat-header">
                <span class="name">{{ item.from_id }}</span>
                <span class="location" v-if="item.location"
                  >({{ item.location }})</span
                >
                <div class="chat-meta">
                  <span class="time">{{ item.create_at }}</span>
                </div>
              </div>
              <div
                class="last-message text-ellipsis"
                v-if="item.message_type == 1"
              >
                {{ item.content }}
              </div>
              <div class="last-message" v-else-if="item.message_type == 2">
                [ 图片 ]
              </div>
            </div>
          </div>

          <!-- 加载更多提示 -->
          <!-- <div class="loading-more" v-if="loading">加载中...</div> -->
        </div>
      </div>

      <!-- 右侧聊天内容区域 -->
      <div class="chat-main">
        <!-- 头部 -->
        <div class="chat-c-header">
          <div class="title">
            <span class="name">{{ currentChat.from_id }}</span>
            <span class="location" v-if="currentChat.location"
              >({{ currentChat.location }})</span
            >
          </div>
          <div class="close-btn" @click="hide">×</div>
        </div>

        <!-- 聊天内容区 -->
        <div class="chat-content">
          <template v-for="(item, index) in chatList">
            <!-- 仅当消息类型不是0时才显示 -->
            <template v-if="item.message_type != 0">
              <!-- 显示时间线 -->
              <div
                v-if="shouldShowTimestamp(index)"
                class="message-timestamp"
                :key="'time-' + index"
              >
                {{ formatMessageTime(item.create_at) }}
              </div>

              <!-- 消息内容 -->
              <div
                :key="'msg-' + index"
                class="message"
                :class="{
                  'service-message': item.is_service == 0,
                  'user-message': item.is_service == 1,
                }"
                @contextmenu.prevent="handleRightClick($event, item, index)"
              >
                <div v-if="item.is_service == 0" class="avatar">
                  {{ currentChat.from_id.charAt(0) }}
                </div>

                <div class="message-content" v-if="item.message_type == 1">
                  <div class="text">{{ item.content }}</div>
                </div>

                <div
                  class="message-content"
                  style="height: 129px"
                  v-if="item.message_type == 2"
                >
                  <div class="text">
                    <PreviewVue
                      :disabled="true"
                      :list="[item.content]"
                      :maxLength="1"
                    ></PreviewVue>
                  </div>
                </div>

                <div v-if="item.is_service == 1" class="avatar">客</div>
              </div>
            </template>
          </template>
        </div>

        <!-- 底部操作栏 -->
        <div class="chat-footer">
          <div class="action-buttons">
            <div class="action-btn">
              <UploadVue @result="handleImg"></UploadVue>
            </div>
            <div class="action-btn">
              <img class="action-icon" src="@/assets/icon1.png" alt="" />
            </div>
            <div class="action-btn">
              <img class="action-icon" src="@/assets/icon2.png" alt="" />
            </div>
            <div class="action-btn">
              <img class="action-icon" src="@/assets/icon3.png" alt="" />
            </div>
          </div>
          <div class="input-area">
            <textarea
              class="message-input"
              placeholder="请输入消息"
              v-model="messageText"
              @keydown.enter.exact="handleKeyDown"
              @keydown.shift.enter="handleShiftEnter"
            ></textarea>
            <button class="send-btn" @click="sendMessage">发送</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 右键菜单 -->
    <div
      v-show="showContextMenu"
      class="context-menu"
      :style="contextMenuStyle"
    >
      <div class="context-menu-item" @click="recallMessage">撤回</div>
    </div>
  </div>
</template>

<script>
import {
  ServiceChat_index,
  ServiceChat_getChatInfo,
  ServiceChat_add,
  ServiceChat_recall,
} from "@/api/chat";
import UploadVue from "@/components/customer-service/Upload.vue";
import PreviewVue from "@/components/customer-service/Preview.vue";
import {
  canRecall,
  shouldShowTimestamp,
  formatMessageTime,
} from "@/utils/utils.js";

export default {
  name: "MaskPopup",
  data() {
    return {
      visible: false,
      searchText: "",
      currentChat: 0,
      chatList: [],
      messageText: "",
      loading: false,
      hasMore: true,
      page: 1,
      limit: 20,
      userChatList: [],
      chatTimer: null,
      disableAutoScroll: false,
      userListTimer: null,
      showContextMenu: false,
      contextMenuStyle: {
        top: "0px",
        left: "0px",
      },
      selectedMessage: null,
      selectedMessageIndex: -1,
      shouldShowTimestamp,
      formatMessageTime,
    };
  },
  components: {
    UploadVue,
    PreviewVue,
  },
  mounted() {
    console.log(111);

    // 点击页面其他区域时，关闭右键菜单
    document.addEventListener("click", this.hideContextMenu);
  },
  watch: {
    chatList: {
      handler(val) {
        if (!this.disableAutoScroll) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    // 获取聊天记录缓存的键名
    getChatStorageKey(clientId) {
      return `service_chat_${clientId}`;
    },

    // 保存聊天记录到本地缓存
    saveChatToStorage(clientId, chatMessages) {
      if (!clientId || !chatMessages || !chatMessages.length) return;

      const key = this.getChatStorageKey(clientId);
      localStorage.setItem(key, JSON.stringify(chatMessages));
    },

    // 从本地缓存加载聊天记录
    loadChatFromStorage(clientId) {
      if (!clientId) return [];

      const key = this.getChatStorageKey(clientId);
      const cachedChat = localStorage.getItem(key);

      return cachedChat ? JSON.parse(cachedChat) : [];
    },
    // 获取用户列表
    async getUserChatList(isLoadMore = false) {
      if (this.loading || (isLoadMore && !this.hasMore)) return;

      try {
        this.loading = true;
        const res = await ServiceChat_index({
          page: this.page,
          limit: this.limit,
        });

        // 如果不是加载更多，而是轮询更新
        if (!isLoadMore) {
          // 保存当前选中的聊天
          const currentChatId = this.currentChat?.id;

          this.userChatList = res;

          // 如果有新消息，更新当前选中的聊天内容
          if (currentChatId) {
            const updatedChat = res.find((chat) => chat.id === currentChatId);
            if (updatedChat) {
              this.currentChat = updatedChat;
            }
          }
        } else {
          // 加载更多的逻辑保持不变
          this.userChatList = [...this.userChatList, ...res];
        }

        // 判断是否还有更多数据
        this.hasMore = res.length === this.limit;

        // 如果是首次加载，选中第一条聊天
        if (!isLoadMore && !this.currentChat && this.userChatList.length > 0) {
          this.selectChat(this.userChatList[0]);
        }
      } catch (error) {
        console.error("获取聊天列表失败:", error);
      } finally {
        this.loading = false;
      }
    },
    // 获取聊天记录
    async getChatInfo(last_id = 0) {
      try {
        const res = await ServiceChat_getChatInfo({
          client_id: this.currentChat.from_id,
          last_id,
        });

        if (last_id == 0) {
          // 首次加载时处理消息
          const messages = await this.processMessages(res);
          // 首次加载时反转数组
          this.chatList = [...messages].reverse();

          // 保存到本地缓存
          this.saveChatToStorage(this.currentChat.from_id, this.chatList);
        } else if (res[0] && last_id != res[0].id) {
          if (res && res.length > 0) {
            const messages = await this.processMessages(res);

            // 检查最后一条消息是否与chatList中最新消息内容相同
            if (this.chatList.length > 0 && messages.length > 0) {
              const lastLocalMsg = this.chatList[this.chatList.length - 1];
              const lastServerMsg = messages[messages.length - 1];

              if (
                lastLocalMsg.content === lastServerMsg.content &&
                lastLocalMsg.message_type === lastServerMsg.message_type
              ) {
                // 如果内容相同，更新本地消息的ID
                this.chatList[this.chatList.length - 1].id = lastServerMsg.id;
                // 移除最后一条服务器消息，避免重复添加
                messages.pop();
              }
            }

            // 添加其余新消息
            if (messages.length > 0) {
              this.chatList.push(...messages);

              // 更新本地缓存
              this.saveChatToStorage(this.currentChat.from_id, this.chatList);
            }
          }
        }
      } catch (error) {
        console.error("获取聊天记录失败:", error);
      }
    },
    // 添加处理消息的方法
    async processMessages(messages) {
      const processedMessages = [];

      for (const message of messages) {
        if (message.message_type == 4 && !message.data && 0) {
          try {
            // 获取订单详情
            const orderRes = await this.$api.orders_read({
              order_sn: message.content,
            });
            if (orderRes.code == 200) {
              message.data = orderRes.result;
            }
          } catch (error) {
            console.error("获取订单详情失败:", error);
          }
        } else if (message.message_type == 3) {
          if (message.content.includes("---")) {
            let arr = message.content.split("---");
            message.content = arr[0];
            message.duration = arr[1];
          }
        } else if (message.message_type == 5) {
          try {
            if (message.content.trim() == "") {
              return;
            }
            const goodsIds = message.content.split(",");
            const goodsDataArray = [];

            for (const goodsId of goodsIds) {
              const goodsRes = await this.$api.goods_read({
                id: goodsId,
              });
              if (goodsRes.code == 200) {
                goodsDataArray.push(goodsRes.result);
              }
            }

            message.data = goodsDataArray;
          } catch (error) {
            console.error("获取商品详情失败:", error);
            message.data = [];
          }
        }
        processedMessages.push(message);
      }
      return processedMessages;
    },
    // 添加获取最后一条消息ID的方法
    lookLastId() {
      const chatList = this.chatList;
      let lastMsg = null;
      for (let i = chatList.length - 1; i >= 0; i--) {
        if (chatList[i] && chatList[i].id) {
          lastMsg = chatList[i];
          console.log(lastMsg, 333);

          return lastMsg;
          break;
        }
      }
      return { id: 0 };
    },
    // 显示弹窗
    show() {
      this.visible = true;
      this.getUserChatList();
      this.startUserListTimer();
    },
    // 隐藏弹窗
    hide() {
      this.visible = false;
      this.stopUserListTimer();
      this.stopChatTimer();
    },
    // 点击遮罩层处理
    handleMaskClick() {
      this.hide();
    },
    selectChat(item) {
      // 如果选中的是同一个聊天，不做任何操作
      if (this.currentChat && this.currentChat.id === item.id) {
        return;
      }

      // 更新当前聊天
      this.currentChat = item;

      // 先从本地缓存加载聊天记录
      this.chatList = this.loadChatFromStorage(item.from_id);

      // 然后请求服务器获取新消息
      const lastId = this.chatList.length > 0 ? this.lookLastId().id : 0;
      this.getChatInfo(lastId);

      this.stopChatTimer();
      setTimeout(() => {
        this.startChatTimer();
      }, 500);
    },
    // 通用的发送消息方法
    async send(type, content) {
      if (!content.trim()) return;

      try {
        // 发送消息到服务器
        await ServiceChat_add({
          message_type: type,
          content: content,
          to_id: this.currentChat.from_id,
        });

        // 滚动到底部
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } catch (error) {
        console.error("发送消息失败:", error);
      }
    },
    // 发送文本消息
    async sendMessage() {
      if (!this.messageText.trim()) return;

      // 先添加到本地聊天列表
      this.chatList.push({
        id: this.lookLastId().id + 1,
        message_type: 1,
        content: this.messageText,
        create_at: this.formatTime(),
        is_service: 1,
      });

      // 更新本地缓存
      this.saveChatToStorage(this.currentChat.from_id, this.chatList);

      await this.send(1, this.messageText);
      // 清空输入框
      this.messageText = "";
    },
    handleImg(params) {
      const { host, url, type } = params;
      this.sendImg(url);
    },
    async sendImg(url) {
      this.chatList.push({
        id: this.lookLastId().id + 1,
        message_type: 2,
        content: url,
        create_at: this.formatTime(),
        is_service: 1,
      });

      // 更新本地缓存
      this.saveChatToStorage(this.currentChat.from_id, this.chatList);

      await this.send(2, url);

      // 滚动到底部
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    // 格式化时间
    formatTime() {
      const date = new Date();
      const pad = (num) => String(num).padStart(2, "0");
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
        date.getSeconds()
      )}`;
    },
    // 滚动事件处理
    handleScroll(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      // 距离底部小于 50px 时加载更多
      if (
        scrollHeight - scrollTop - clientHeight < 50 &&
        !this.loading &&
        this.hasMore
      ) {
        this.page += 1;
        this.getUserChatList(true);
      }
    },
    // 启动聊天轮询
    startChatTimer() {
      if (this.chatTimer) {
        clearInterval(this.chatTimer);
      }

      this.chatTimer = setInterval(() => {
        const chatList = this.chatList;
        if (chatList && chatList.length > 0) {
          let lastMsg = this.lookLastId();

          if (lastMsg && lastMsg.id) {
            this.getChatInfo(lastMsg.id);
          }
        }
      }, 5000);
    },
    // 停止聊天轮询
    stopChatTimer() {
      if (this.chatTimer) {
        clearInterval(this.chatTimer);
        this.chatTimer = null;
      }
    },
    // 滚动到底部
    scrollToBottom() {
      const chatContent = document.querySelector(".chat-content");

      if (chatContent) {
        chatContent.scrollTop = chatContent.scrollHeight;
      }
    },
    // 启动用户列表轮询
    startUserListTimer() {
      // 先清除可能存在的定时器
      if (this.userListTimer) {
        clearInterval(this.userListTimer);
      }

      // 设置新的定时器
      this.userListTimer = setInterval(() => {
        // 获取最新的用户列表
        this.getUserChatList();
      }, 10000); // 每10秒轮询一次
    },
    // 停止用户列表轮询
    stopUserListTimer() {
      if (this.userListTimer) {
        clearInterval(this.userListTimer);
        this.userListTimer = null;
      }
    },
    // 处理回车键按下
    handleKeyDown(e) {
      if (e.shiftKey) return;
      e.preventDefault();
      // 发送消息
      this.sendMessage();
    },

    // Shift+Enter
    handleShiftEnter() {},

    // 处理右键点击
    handleRightClick(event, message, index) {
      if (!canRecall(message)) {
        return;
      }

      // 记录选中的消息和索引
      this.selectedMessage = message;
      this.selectedMessageIndex = index;

      // 设置菜单位置
      this.contextMenuStyle = {
        top: `${event.clientY}px`,
        left: `${event.clientX}px`,
      };

      // 显示菜单
      this.showContextMenu = true;

      // 添加点击其他区域关闭菜单的事件
      this.$nextTick(() => {
        document.addEventListener("click", this.hideContextMenu);
      });
    },

    // 隐藏右键菜单
    hideContextMenu() {
      this.showContextMenu = false;
      document.removeEventListener("click", this.hideContextMenu);
    },

    // 撤回消息
    async recallMessage() {
      if (this.selectedMessageIndex >= 0 && canRecall(this.selectedMessage)) {
        try {
          // 隐藏菜单
          this.hideContextMenu();

          const messageIdToRecall = this.selectedMessage.id;

          // 根据ID查找消息，而不是使用索引
          const indexToRemove = this.chatList.findIndex(
            (msg) => msg.id === messageIdToRecall
          );

          // 如果找到消息，则删除它
          if (indexToRemove !== -1) {
            this.chatList.splice(indexToRemove, 1);

            // 更新本地缓存
            this.saveChatToStorage(this.currentChat.from_id, this.chatList);
          }

          // 调用撤回消息API
          await ServiceChat_recall({
            id: messageIdToRecall,
          });
        } catch (error) {
          console.error("撤回消息失败:", error);
        }
      }
    },
  },
  beforeDestroy() {
    this.stopChatTimer();
    this.stopUserListTimer();

    // 移除事件监听器
    document.removeEventListener("click", this.hideContextMenu);
  },
};
</script>

<style scoped>
.mask-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  width: 800px;
  height: 600px;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.chat-container {
  display: flex;
  flex-direction: row;
  width: 1096px;
  height: 80%;
}

.chat-c-header {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title .name {
  font-size: 16px;
  font-weight: bold;
}

.title .location {
  color: #666;
  margin-left: 8px;
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
  color: #999;
}

.chat-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.message {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.service-message {
  justify-content: flex-start;
}

.user-message {
  justify-content: flex-end;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4169e1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-content {
  max-width: 70%;
}

.text {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
  word-break: break-all;
}

.user-message .text {
  background-color: #4169e1;
  color: white;
}

.quick-links {
  margin-top: 10px;
}

.quick-links a {
  display: inline-block;
  margin-right: 10px;
  color: #4169e1;
  text-decoration: none;
}

.product-card {
  width: 435px;
  height: 177px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
}

.product-card img {
  width: 135px;
  height: 134px;
  margin-right: 12px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 3em;
}

.product-info {
  flex: 1;
}

.product-name {
  font-size: 22px;
  color: #353535;
  margin-bottom: 29px;
}

.product-desc {
  width: 236px;
  color: #9b9b9b;
  font-size: 15px;
  margin-bottom: 42px;
}

.product-price {
  color: #d57033;
  font-size: 14px;
}

.chat-footer {
  padding: 10px 20px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.action-buttons {
  display: flex;
  gap: 30px;
}

.action-btn {
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666;
}

.action-icon {
  width: 100%;
  height: 100%;
}

.input-area {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.message-input {
  flex: 1;
  height: 68px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  resize: none;
  outline: none;
  font-size: 14px;
  line-height: 1.5;
}

.message-input:focus {
  border-color: #4169e1;
}

.send-btn {
  width: 64px;
  height: 32px;
  background-color: #4169e1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-btn:hover {
  background-color: #3157d3;
}

/* 新增样式 */
.chat-list {
  width: 350px;
  border-right: 1px solid #eee;
  display: flex;
  flex-direction: column;
}

.search-box {
  padding: 15px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.search-icon {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.search-box input {
  width: 100%;
  padding: 8px 15px 8px 35px;
  border: 1px solid #eee;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
}

.chat-list-content {
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.chat-item {
  padding: 15px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;
}

.chat-item:hover {
  background-color: #f9f9f9;
}

.chat-item.active {
  background-color: #f0f7ff;
}

.chat-item .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 12px;
  flex-shrink: 0;
}

.chat-info {
  flex: 1;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.chat-header .name {
  font-weight: 500;
  margin-right: 5px;
}

.chat-header .location {
  color: #999;
  font-size: 12px;
}

.last-message {
  width: 230px;
  color: #666;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
  width: 74px;
}

.time {
  font-size: 12px;
  color: #999;
}

.badge {
  background-color: #ff4d4f;
  color: white;
  font-size: 12px;
  padding: 0 6px;
  border-radius: 10px;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* 添加加载更多样式 */
.loading-more {
  text-align: center;
  padding: 10px 0;
  color: #999;
  font-size: 14px;
}

/* 添加右键菜单样式 */
.context-menu {
  position: fixed;
  z-index: 1000;
  background: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px 0;
  min-width: 100px;
}

.context-menu-item {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.context-menu-item:hover {
  background-color: #f5f5f5;
}

/* 为可撤回的消息添加特殊样式 */
.user-message .message-content {
  position: relative;
}

/* 消息时间戳样式 */
.message-timestamp {
  text-align: center;
  margin: 15px 0;
  font-size: 12px;
  color: #999;
  position: relative;
}

.message-timestamp::before,
.message-timestamp::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 20%;
  height: 1px;
  background-color: #e5e5e5;
}

.message-timestamp::before {
  left: 20%;
}

.message-timestamp::after {
  right: 20%;
}
</style>
